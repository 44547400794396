<template>
  <b-container class="bg-white" v-if="permission">
    <b-row v-if="!loading" class="mb-4">
      <b-col>
        <h2 class="mb-4">Regulations journal</h2>
        <div v-if="logs.length > 0">
          <div class="mb-4" v-for="log in logs" :key="'log-' + log.id">
            <div><b-link :to="{ name: 'RegDecompWorkspace', params: { id: log.regulation_id }}"><strong>{{log.regulation.name_short}}</strong></b-link> - {{log.obligation_id}} - {{log.obligation.name}}</div>
            <div>{{log.username}} {{log.action}} at {{moment(log.created_at).format('HH:mm')}} on {{moment(log.created_at).format('YYYY-MM-DD')}}</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="loading" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
// import Dashboard from '../../components/workflow/article/Dashboard.vue'

export default {
  name: 'RegulationJournal',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "Obligations Journal"
    this.$stat.log({ page: 'regulationjournal', action: 'open page' })
    this.permission = ac.can(this.user.acgroups).readAny('regulation').granted
    if (this.permission) {
      this.load()
    }
  },
  data () {
    return {
      loading: true,
      logs: [],
      moment: moment,
      permission: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.logs = await this.$Amplify.API.get('cosmos', '/obligations/logs')
        this.loading = false
      } catch (e) {
        this.$logger.warn('loading regulations error ', e)
      }
    }
  }
}
</script>

<style>
</style>
